import {
  agileList,
  apiList,
  askQuestionList,
  blogList,
  bookList,
  careerList,
  comicList,
  communityList,
  computerScienceList,
  courseList,
  databaseList,
  docList,
  documentList,
  dotnetList,
  examList,
  generalList,
  gitList,
  glossaryList,
  hostingList,
  interactiveLearnList,
  jobList,
  libraryList,
  linuxList,
  marketingList,
  newsList,
  quizList,
  reactList,
  regexList,
  seoList,
  serviceList,
  sqlList,
  statisticsList,
  testList,
  toolList,
  tutorialList,
  typeScriptList,
  uiuxList,
  vpnList,
  websiteBuilderList,
  youtubeList,
  aiServiceList,
  diagramList,
  restList,
  collectionList,
  animationList,
  cheatSheetsList,
  publishList,
  uiLibraryList,
  remoteToolsList,
  themeList,
  conferenceList,
  packageList,
  gameDevelopList,
  vimList,
  aiArtList,
  dojoList,
  frameworkList,
  bundlerList,
  gaList,
  chartList,
  mathList,
  ormList,
  openDataList,
  dummyList,
  managementList,
  iconList,
  remoteJobList,
  interviewList,
  noCodeList,
  cmsList,
  benchmarkList,
  userMonitoringList,
} from "./list";

export const routeList = [
  { path: "sql", text: "SQL", importList: sqlList },
  { path: "icon", text: "icon", importList: iconList },
  { path: "api", text: "API", importList: apiList },
  { path: "typescript", text: "TypeScript", importList: typeScriptList },
  { path: "dotnet", text: ".NET", importList: dotnetList },
  { path: "react", text: "React", importList: reactList },
  { path: "math", text: "Math", importList: mathList },
  { path: "orm", text: "ORM", importList: ormList },
  { path: "benchmark", text: "Benchmark", importList: benchmarkList },
  { path: "ga", text: "Google Analytics", importList: gaList },
  { path: "openData", text: "Open Data", importList: openDataList },
  { path: "dummy", text: "Dummy Data", importList: dummyList },
  { path: "job", text: "Job Hunting", importList: jobList },
  { path: "remoteJob", text: "Remote Jobs", importList: remoteJobList },
  { path: "interview", text: "Interview", importList: interviewList },
  { path: "career", text: "Career", importList: careerList },
  { path: "cms", text: "CMS", importList: cmsList },
  { path: "diagram", text: "Diagram", importList: diagramList },
  { path: "bundler", text: "Bundler", importList: bundlerList },
  { path: "community", text: "Community", importList: communityList },
  { path: "management", text: "Management", importList: managementList },
  { path: "aiService", text: "AI Service", importList: aiServiceList },
  { path: "noCode", text: "No Code", importList: noCodeList },
  {
    path: "themeBuilder",
    text: "Website Builder",
    importList: websiteBuilderList,
  },
  { path: "tool", text: "Tool", importList: toolList },
  { path: "docs", text: "Docs", importList: docList },
  { path: "news", text: "News", importList: newsList },
  { path: "general", text: "General", importList: generalList },
  { path: "database", text: "Database", importList: databaseList },
  { path: "vpn", text: "VPN", importList: vpnList },
  { path: "hosting", text: "Hosting", importList: hostingList },
  { path: "document", text: "Document", importList: documentList },
  { path: "ask", text: "Ask", importList: askQuestionList },
  {
    path: "computerScience",
    text: "Computer Science",
    importList: computerScienceList,
  },
  { path: "blog", text: "Blog", importList: blogList },
  { path: "tutorial", text: "Tutorial", importList: tutorialList },
  { path: "youtube", text: "Youtube", importList: youtubeList },
  { path: "courses", text: "Online Courses", importList: courseList },
  { path: "restapi", text: "REST API", importList: restList },
  { path: "book", text: "Book", importList: bookList },
  { path: "linux", text: "Linux", importList: linuxList },
  { path: "comic", text: "Comic", importList: comicList },
  {
    path: "interactive",
    text: "Interactive learning",
    importList: interactiveLearnList,
  },
  { path: "agile", text: "Agile", importList: agileList },
  { path: "chart", text: "Chart", importList: chartList },
  { path: "exam", text: "Exam", importList: examList },
  { path: "library", text: "Library", importList: libraryList },
  { path: "statistics", text: "Statistics", importList: statisticsList },
  {
    path: "usermonitoring",
    text: "User Monitoring",
    importList: userMonitoringList,
  },
  { path: "services", text: "Services", importList: serviceList },
  { path: "regex", text: "Regex", importList: regexList },
  { path: "quiz", text: "Quiz", importList: quizList },
  { path: "test", text: "Test", importList: testList },
  { path: "seo", text: "SEO", importList: seoList },
  { path: "glossary", text: "Glossary", importList: glossaryList },
  { path: "git", text: "Git", importList: gitList },
  { path: "marketing", text: "Marketing", importList: marketingList },
  { path: "uiux", text: "UI UX", importList: uiuxList },
  { path: "collection", text: "Collection", importList: collectionList },
  { path: "animation", text: "Animation", importList: animationList },
  { path: "vim", text: "Vim", importList: vimList },
  { path: "dojo", text: "Dojo", importList: dojoList },
  { path: "conference", text: "Conference", importList: conferenceList },
  { path: "cheatsheet", text: "Cheat Sheet", importList: cheatSheetsList },
  { path: "publish", text: "Publish", importList: publishList },
  { path: "package", text: "Package", importList: packageList },
  { path: "game", text: "Game Develop", importList: gameDevelopList },
  { path: "uiLibrary", text: "UI Library", importList: uiLibraryList },
  { path: "remoteTools", text: "Remote Tools", importList: remoteToolsList },
  { path: "theme", text: "Theme", importList: themeList },
  { path: "framework", text: "Framework", importList: frameworkList },
  { path: "aiart", text: "AI Art", importList: aiArtList },
  { path: "/", text: "Home", importList: null },
];
